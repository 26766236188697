import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDButton from "components/MDButton";
import Search from "antd/es/input/Search";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetUsers, USERSTATUS } from "apis/response";
// import AntTable from "./components/table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Badge, Pagination, PaginationProps, Popconfirm, Select, Table } from "antd";
import { paramsFilter } from "utils/ParamsFilter";
// import { tableColumn } from "./components/data";
import { inputToPhone } from "utils/PhoneString";
import { emptyString, genderString } from "utils";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import MDBadge from "components/MDBadge";
import InsuranceTalkDialog from "./components/InsuranceTalkDialog";

type OptionType = {
  value: string;
  label: string;
};
type UpdateType = "bankAccount" | "idCard" | "safetyCertificate";
function MemberList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page");
  const city = params.get("city");
  const district = params.get("district");
  const insuranceDay = params.get("insuranceDay");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IGetUsers[]>([]);

  /** 지역 필터링 */
  const [cityList, setCityList] = useState<OptionType[]>([]);
  const [districtList, setDistrictList] = useState<OptionType[]>([]);

  const [open, setOpen] = useState(false);

  const handleOpenState = () => setOpen((prev) => !prev);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search,
        page,
        city,
        district,
        insuranceDay,
      })}`
    );
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
        city,
        district,
        insuranceDay,
      })}`
    );
  };

  const handleUpdateUser = (userId: string | number, type: UpdateType) => {
    let updateData;
    switch (type) {
      case "bankAccount":
        updateData = { bankAccount: "1" };
        break;
      case "idCard":
        updateData = { idCardPass: true };
        break;
      case "safetyCertificate":
        updateData = { safetyCertificatePass: true };
        break;
      default:
        console.log("Invalid update type");
        return;
    }

    Apis.putUserGeneralById(userId, updateData)
      .then((resolve) => {
        Apis.getUsers({ page: currentPage, perPage: 10, search, sort, order })
          .then((resolve) => {
            setData(resolve.data.data);
            setTotal(resolve.data.total);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCityFilter = (value: any) => {
    setCurrentPage(1);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search,
        page: 1,
        city: value,
        district: null,
        insuranceDay,
      })}`
    );
  };
  const handleDistrictFilter = (value: any) => {
    setCurrentPage(1);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search,
        page: 1,
        city,
        district: value,
        insuranceDay,
      })}`
    );
  };

  const handleChangeInsuranceDayFilter = (filters: any) => {
    const insuranceFilter = filters.insuranceDay?.[0] || null;
    console.log(insuranceFilter);

    setCurrentPage(1);
    navigate(
      `/member-management/member-list${paramsFilter({
        sort,
        order,
        search,
        page: 1,
        city,
        district,
        insuranceDay: insuranceFilter,
      })}`
    );
  };

  useEffect(() => {
    Apis.getUsers({
      page: currentPage,
      perPage: 10,
      search,
      sort,
      order,
      city,
      district,
      insuranceDay,
    })
      .then((resolve) => {
        // console.log(resolve.data);
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search, sort, order, city, district, insuranceDay]);

  useEffect(() => {
    Apis.getCodeListCity().then((resolve) => {
      const cityList = resolve.data.data.map((item) => {
        return { value: item.city, label: item.city };
      });
      setCityList(cityList);
    });
  }, []);

  useEffect(() => {
    if (city) {
      Apis.getCodeListCityDistrict(city).then((resolve) => {
        const districtList = resolve.data.data.map((item) => {
          return { value: item.district, label: item.district };
        });
        setDistrictList(districtList);
      });
    } else {
      setDistrictList([]);
    }
  }, [city]);

  const tableColumn: ColumnsType<IGetUsers> = [
    {
      title: "이름",
      dataIndex: "name",
      ellipsis: true,
      render: (name: string) => emptyString(name),
    },
    {
      title: "핸드폰번호",
      dataIndex: "phone",
      ellipsis: true,
      render: (phone: string) => inputToPhone(phone),
    },
    {
      title: "주소",
      dataIndex: "firstAddress", // "secondAddress"를 추가 정보로 표시할 수 있도록 고려해야 할 수 있습니다.
      ellipsis: true,
      render: (firstAddress: string) => emptyString(firstAddress),
    },
    {
      title: "지역",
      dataIndex: "district",
      ellipsis: true,
      render: (district, record) =>
        (record?.city === "" ? "미입력" : record?.city) +
        "/" +
        (district === "" ? "미입력" : district),
    },
    {
      title: "고용보험",
      dataIndex: "insuranceDay",
      ellipsis: true,
      align: "center",
      // 필터 추가
      filterMultiple: false,
      //리셋 텍스트 '초기화'로 변경
      filters: [
        { text: "인증", value: "on" },
        { text: "미인증", value: "off" },
      ],
      render: (insuranceDay: number) => {
        if (insuranceDay === 0) {
          return <MDBadge badgeContent="미인증" container size="sm" color="error" />;
        } else {
          return <MDBadge badgeContent="인증" container size="sm" color="success" />;
        }
      },
    },
    {
      title: "근로내역",
      dataIndex: "recentInsuranceDay", // "secondAddress"를 추가 정보로 표시할 수 있도록 고려해야 할 수 있습니다.
      ellipsis: true,
      align: "right",

      render: (recentInsuranceDay: number) => (recentInsuranceDay ?? 0) + "일",
    },
    {
      title: "생년월일",
      dataIndex: "birth",
      ellipsis: true,
      render: (birth: string) => emptyString(birth),
    },
    {
      title: "성별",
      dataIndex: "gender",
      ellipsis: true,
      align: "center",
      render: (gender: string) => genderString(gender),
    },
    {
      title: "가입일자",
      dataIndex: "createdAt",
      ellipsis: true,
      render: (createdAt: string) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "계좌인증",
      dataIndex: "bankAccount",
      ellipsis: true,
      render: (_, record) => {
        if (record.bankAccount !== "") {
          return <Badge status="success" text={"인증완료"} />;
        } else {
          return (
            <Popconfirm
              cancelText="취소"
              okText="임시인증"
              icon={<QuestionCircleOutlined style={{ color: "green" }} />}
              title="계좌인증 인증 변경"
              description="승인하시면 계좌인증 인증완료 상태를 변경하게 됩니다."
              onConfirm={() => {
                handleUpdateUser(record.userId, "bankAccount");
              }}
              onPopupClick={(e) => e.stopPropagation()}
            >
              <MDButton
                size="small"
                color="warning"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  // handleUpdatesafetyCertification(record.userId);
                }}
              >
                미인증
              </MDButton>
            </Popconfirm>
          );
        }
      },
    },
    {
      title: "신분증",
      dataIndex: "idCardPass",
      ellipsis: true,
      align: "center",
      render: (idCardPass: boolean, record) =>
        !idCardPass ? (
          <Popconfirm
            cancelText="취소"
            okText="임시인증"
            icon={<QuestionCircleOutlined style={{ color: "green" }} />}
            title="신분증 인증 변경"
            description="승인하시면 신분증 인증완료 상태를 변경하게 됩니다."
            onConfirm={() => {
              handleUpdateUser(record.userId, "idCard");
            }}
            onPopupClick={(e) => e.stopPropagation()}
          >
            <MDButton
              size="small"
              color="warning"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                // handleUpdatesafetyCertification(record.userId);
              }}
            >
              미인증
            </MDButton>
          </Popconfirm>
        ) : (
          <Badge status="success" text={"인증완료"} />
        ),
    },
    {
      title: "안전교육이수증",
      dataIndex: "safetyCertificatePass",
      ellipsis: true,
      align: "center",
      render: (safetyCertificatePass: boolean, record) =>
        !safetyCertificatePass ? (
          <Popconfirm
            cancelText="취소"
            okText="임시인증"
            icon={<QuestionCircleOutlined style={{ color: "green" }} />}
            title="안전교육이수증 인증 변경"
            description="승인하시면 안전교육이수증 인증완료 상태를 변경하게 됩니다."
            onConfirm={() => {
              handleUpdateUser(record.userId, "safetyCertificate");
            }}
            onPopupClick={(e) => e.stopPropagation()}
          >
            <MDButton
              size="small"
              color="warning"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                // handleUpdatesafetyCertification(record.userId);
              }}
            >
              미인증
            </MDButton>
          </Popconfirm>
        ) : (
          <Badge status="success" text={"인증완료"} />
        ),
    },
    // "기타정보", "맞춤일자리", "구분" 컬럼은 인터페이스에 대응하는 필드가 명확하지 않으므로 수정이 필요합니다.
    {
      title: "회원상태",
      dataIndex: "status",
      align: "center",
      render: (status: USERSTATUS) => {
        let badgeStatus: "default" | "processing" | "success" | "error" | "warning" = "default";
        let text = "";

        switch (status) {
          case "onRegist":
            badgeStatus = "warning"; // 'warning'은 '미인증' 상태를 나타내는 노란색 뱃지를 의미합니다.
            text = "승인 대기";
            break;
          case "general":
            badgeStatus = "success"; // 'success'는 '인증 완료' 상태를 나타내는 초록색 뱃지를 의미합니다.
            text = "인증 완료";
            break;
          case "delete":
            badgeStatus = "error"; // 'error'는 '삭제' 상태를 나타내는 빨간색 뱃지를 의미합니다.
            text = "탈퇴 계정";
            break;
          case "tempRegist":
            badgeStatus = "processing"; // 'error'는 '삭제' 상태를 나타내는 빨간색 뱃지를 의미합니다.
            text = "임시 가입 계정";
            break;
          case "ban":
            badgeStatus = "default"; // 'error'는 '삭제' 상태를 나타내는 빨간색 뱃지를 의미합니다.
            text = "정지 유저";
            break;
          default:
            // 기본값 설정, 필요에 따라 조정할 수 있습니다.
            badgeStatus = "default";
            text = "알 수 없음";
        }

        return <Badge status={badgeStatus} text={text} />;
      },
      ellipsis: true,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          <Grid item xs={12} md={4}>
            {/* target="_blank"의 문제점
            1. 보안상 취약점이 생긴다

            2. 퍼포먼스가 떨어질 수 있다
            target="_blank"가 사용된 링크에 의해 열린 링크된 페이지는 링크를 건 페이지와 같은
            프로세스를 통해서 실행됩니다. 그러므로 가령 링크된 페이지에서 높은 부하를 유발하는
            JavaScript가 실행되고 있으면 링크를 건 페이지에도 그 영향이 미쳐져 의도하지 않게
            퍼포먼스가 떨어지는 문제가 발생할 수도 있습니다.
             */}
            {/* target="_blank"의 문제점 해결 방법

            1. noopener 지정
            noopener(노오프너)를 지정하면, 링크된 페이지에서 window.opener을 사용해서 링크를 건
            페이지를 참조(reference)할 수 없게 됩니다. 더불어 링크된 페이지와 링크를 건 페이지는
            별개의 프로세스로 취급되기 때문에 서로 연결되어 퍼포먼스가 떨어지는 일도 없게 됩니다.

            2. noreferrer 지정
            noreferrer(노리퍼러)를 지정하면 다른 페이지로 이동할 때, 링크를 건
            페이지의 주소 등의 정보를 브라우저가 Referer: HTTP 헤더로 리퍼러(referer 또는
            referrer)로서 송신하지 않습니다.
             */}
            <a
              href={`${process.env.REACT_APP_ENDPOINT}user/data/download`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MDButton endIcon={<Icon>downloadIcon</Icon>} variant="gradient" color="info">
                회원 정보 엑셀 다운
              </MDButton>
            </a>
            <MDButton
              style={{ marginLeft: 5 }}
              variant="gradient"
              color="dark"
              endIcon={<Icon>notificationsIcon</Icon>}
              onClick={handleOpenState}
              // LinkComponent={Link}
              // to={`/member-management/member-list`}
            >
              고용보험 알림톡 전송
            </MDButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <MDBox display="flex" gap={1} justifyContent="end">
              <Select
                style={{ minWidth: 150 }}
                // defaultValue={city}
                value={city || null}
                placeholder="시/도 선택"
                options={[{ label: "전체", value: null }, ...cityList]}
                onChange={handleCityFilter}
              />

              <Select
                style={{ minWidth: 150 }}
                placeholder="시/군/구 선택"
                // defaultValue={district}
                value={district || null}
                disabled={districtList.length ? false : true}
                options={[{ label: "전체", value: null }, ...districtList]}
                onChange={handleDistrictFilter}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <Search
              maxLength={30}
              placeholder="회원의 이름 휴대전화번호를 검색하세요"
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
        </Grid>

        <Card>
          <Table
            pagination={false}
            dataSource={data}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onChange={(_, filters) => handleChangeInsuranceDayFilter(filters)}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/member-management/member-details/${record.userId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
      {open ? <InsuranceTalkDialog open={open} handleOpenState={handleOpenState} /> : null}
    </DashboardLayout>
  );
}
export default MemberList;
