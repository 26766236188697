import { Divider, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Card, Form, Input, InputNumber, Popconfirm, message } from "antd";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ListItem from "./components/ListItem";

import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import ApplicationReview from "./components/ApplicationReview";

import AttendanceCheck from "./components/AttendanceCheck";
import DepartureCheck from "./components/DepartureCheck";
import PerformanceEvaluation from "./components/PerformanceEvaluation";
import { IWorkById } from "apis/response";
import Apis from "apis/remotes";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import PaymentExample from "./components/PaymentExample";
import STcomp from "components/STcomp";
import { inputToPhone } from "utils/PhoneString";
import MDImage from "components/MDImage";
import { useForm } from "antd/es/form/Form";

function getSteps(): string[] {
  return ["1. 지원확인", "2. 출근확인", "3. 퇴근확인", "4. 평가", "5.입금"];
}
function getPrevStepText(stepIndex: number) {
  switch (stepIndex) {
    case 1:
      return "모집화면";
    case 2:
      return "출근 확인 완료";
    case 3:
      return "퇴근 확인 완료";
    case 4:
      return "평가 완료";

    default:
      return null;
  }
}
function getNextStepText(stepIndex: number) {
  switch (stepIndex) {
    case 0:
      return "출근 확인 완료";
    case 1:
      return "퇴근 확인 완료";
    case 2:
      return "평가 완료";
    case 3:
      return "입금 완료";
    default:
      return null;
  }
}
function SiteDetails(): JSX.Element {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [announcement, setAnnouncement] = useState<IWorkById>();
  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;
  function getStepContent(stepIndex: number): JSX.Element {
    switch (stepIndex) {
      case 0:
        return (
          <ApplicationReview
            workId={id}
            workStatus={announcement?.status ?? "모집중"}
            handleUpdateAnnouncementStatus={handleUpdateAnnouncementStatus}
          />
        );
      case 1:
        return (
          <AttendanceCheck
            workId={id}
            handleUpdateAnnouncementStatus={handleUpdateAnnouncementStatus}
          />
        );
      case 2:
        return (
          <DepartureCheck
            workId={id}
            handleUpdateAnnouncementStatus={handleUpdateAnnouncementStatus}
          />
        );
      case 3:
        return <PerformanceEvaluation workId={id} />;
      case 4:
        return <PaymentExample workId={id} />;
      default:
        return null;
    }
  }

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleUpdateAnnouncementStatus = () => {
    Apis.getWorkById(id)
      .then((resolve) => {
        setAnnouncement(resolve.data);
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "");
      });
  };

  const handleDeleteAnnouncement = () => {
    if (!announcement.workStart) {
      message.error("공고 시작일이 설정되지 않았습니다.");
      return;
    }
    const workStartDate = dayjs(announcement.workStart);
    const today = dayjs();
    const twoDaysBeforeWorkStart = workStartDate.subtract(2, "day");

    if (today.isBefore(twoDaysBeforeWorkStart)) {
      Apis.deleteWork(id)
        .then((resolve) => {
          message.success("공고가 삭제되었습니다.");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // 조건을 만족하지 않으면 삭제 불가
      message.error("공고 시작일 2일 전에만 삭제가능합니다.");
    }
  };

  const renderEditButtonBox = () => {
    if (loading === false && announcement?.status !== "모집완료") {
      return (
        <MDBox my={2} display={"flex"} gap={2} justifyContent={"end"}>
          <MDButton
            LinkComponent={Link}
            to={
              announcement?.isLottery === true
                ? `/announcement-management/lottery-update-announcement/${id}`
                : `/announcement-management/update-announcement/${id}`
            }
            color="warning"
            size="small"
          >
            공고 수정
          </MDButton>
        </MDBox>
      );
    } else {
      return null;
    }

    // 조건이 만족하지 않는 경우 렌더링하지 않음 (또는 대체 UI 표시)
  };

  // const handleUpdateMaxRecruitment = (formData: FormData) => {
  //   Apis.putWorkRecruit(id, formData)
  //     .then((resolve) => {
  //       setAnnouncement((prev) => {
  //         return {
  //           ...prev,
  //         };
  //       });
  //       message.success("모집인원이 수정되었습니다.");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // const renderUpdateMaxRecruitment = () => {
  //   return announcement?.status === "모집중" ? (
  //     <Form form={form} onFinish={handleUpdateMaxRecruitment}>
  //       <MDBox display={"flex"} alignItems={"center"}>
  //         <FormItem
  //           name="maxRecruitment"
  //           initialValue={{ maxRecruitment: announcement?.maxRecruitment ?? 0 }}
  //           rules={[
  //             { required: true, message: "숫자를 입력하세요" },
  //             { min: 1, message: "최소 모집인원은 1명입니다.", type: "number" },
  //           ]}
  //         >
  //           <InputNumber
  //             min={1}
  //             // defaultValue={announcement?.maxRecruitment ?? 0}
  //             style={{
  //               width: "50px",
  //               marginRight: "10px",
  //             }}
  //           />
  //         </FormItem>
  //         <FormItem>
  //           <MDButton type="submit" variant="contained" color="warning" size="small">
  //             수정
  //           </MDButton>
  //         </FormItem>
  //       </MDBox>
  //     </Form>
  //   ) : (
  //     announcement?.maxRecruitment ?? "0"
  //   );
  // };

  const renderDeleteButtonBox = () => {
    if (loading === false && announcement?.workStart) {
      const workStartDay = dayjs(announcement.workStart);
      const twoDaysBeforeStart = workStartDay.subtract(2, "day");
      const today = dayjs();

      // 오늘 날짜가 공고 시작일 2일 전 이전인지 확인합니다.
      if (today.isBefore(twoDaysBeforeStart)) {
        return (
          <MDBox my={2} display={"flex"} gap={2} justifyContent={"end"}>
            <Popconfirm
              okButtonProps={{
                danger: true,
              }}
              icon={null}
              placement="bottom"
              title={"공고를 삭제하시겠습니까?"}
              okText="삭제"
              cancelText="취소"
              onConfirm={() => handleDeleteAnnouncement()}
            >
              <MDButton color="error" size="small">
                공고 삭제
              </MDButton>
            </Popconfirm>
          </MDBox>
        );
      }
    }

    // 조건이 만족하지 않는 경우 렌더링하지 않음 (또는 대체 UI 표시)
    return null;
  };

  useEffect(() => {
    Apis.getWorkById(id)
      .then((resolve) => {
        setAnnouncement(resolve.data);
        form.setFieldsValue({
          maxRecruitment: resolve.data.maxRecruitment,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, activeStep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display={"flex"} justifyContent={"flex-end"} gap={1}>
          {renderEditButtonBox()}
          {renderDeleteButtonBox()}
        </MDBox>
        <Card>
          <MDBox display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h5">{announcement?.address}</Typography>
            <Typography variant="h5">
              {dayjs(announcement?.workDay).format("YYYY년 MM월 DD일")}
            </Typography>
          </MDBox>
          <MDBox p={2}>
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <MDImage
                    image={announcement?.image}
                    style={{
                      width: "100%",
                      maxWidth: 600,
                      borderRadius: 12,
                      height: "100%",
                      maxHeight: 320,
                      objectFit: "cover",
                    }}
                    alt="site_thumb_nail"
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={4}>
                  <ListItem label={"원청"} values={announcement?.company ?? ""} />
                  <ListItem
                    label={"공고 담당자"}
                    values={inputToPhone(announcement?.managerName ?? "")}
                  />
                  <ListItem
                    label={"공고 담당자 연락처"}
                    values={inputToPhone(announcement?.managerPhone ?? "")}
                  />
                  <ListItem
                    label={"현장 담당자"}
                    values={inputToPhone(announcement?.fieldManagerName ?? "")}
                  />
                  <ListItem
                    label={"현장 담당자 연락처"}
                    values={inputToPhone(announcement?.fieldManagerPhone ?? "")}
                  />
                  {announcement?.fieldEvaluatorName ? (
                    <>
                      <ListItem
                        label={"현장 평가 담당자"}
                        values={inputToPhone(announcement?.fieldEvaluatorName ?? "")}
                      />
                      <ListItem
                        label={"현장 평가 담당자 연락처"}
                        values={inputToPhone(announcement?.fieldEvaluatorPhone ?? "")}
                      />
                    </>
                  ) : (
                    <ListItem label={"현장 평가 담당자"} values={"미지정"} />
                  )}

                  <MDBox opacity={0.3}>
                    <Divider />
                  </MDBox>
                  <ListItem label={"공고제목"} values={announcement?.title ?? ""} />
                  <ListItem
                    label={"공고게시기간"}
                    values={announcement?.workStart + " ~ " + announcement?.workEnd}
                  />
                  <ListItem
                    label={"근무일/근무시간"}
                    values={
                      dayjs(announcement?.workDay).format("YYYY-MM-DD") +
                      " / " +
                      announcement?.workTime
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={4}>
                  <ListItem label={"공수"} values={announcement?.manDay ?? ""} />
                  <ListItem
                    label={"급여"}
                    values={(announcement?.pay.toLocaleString() ?? "0") + "원"}
                  />
                  <ListItem label={"급여형태"} values={announcement?.payType ?? ""} />
                  <ListItem label={"주소"} values={announcement?.address ?? ""} />
                  <ListItem label={"상세 주소"} values={announcement?.addressDetail ?? ""} />
                  <ListItem label={"내용"} values={announcement?.content ?? ""} />
                  <ListItem label={"경력"} values={announcement?.requiredExperience ?? ""} />
                  <ListItem label={"모집인원"} values={announcement?.maxRecruitment ?? "0"} />
                  <ListItem label={"확정인원"} values={announcement?.currentRecruitment ?? "0"} />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
              {activeStep === 0 ? (
                <MDBox />
              ) : (
                <MDButton variant="gradient" color="info" onClick={handleBack}>
                  &laquo;{getPrevStepText(activeStep)}
                </MDButton>
              )}
              {isLastStep ? (
                <MDBox />
              ) : (
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={isLastStep}
                  onClick={!isLastStep ? handleNext : undefined}
                >
                  {getNextStepText(activeStep)} &raquo;
                </MDButton>
              )}
            </MDBox>
            <MDBox borderRadius="12px" width={"100%"} p={3} bgColor="#F0F0F0" mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={2.4}>
                  <STcomp
                    title={"모집인원"}
                    amount={announcement?.maxRecruitment ?? "0"}
                    color="secondary"
                    variant="contained"
                    fontSize={24}
                  />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <STcomp
                    title={"지원자"}
                    amount={announcement?.workApplyCount ?? "0"}
                    color="secondary"
                    variant="contained"
                    fontSize={24}
                  />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <STcomp
                    title={"지원승인"}
                    amount={announcement?.currentRecruitment ?? "0"}
                    color="secondary"
                    variant="contained"
                    fontSize={24}
                  />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <STcomp
                    title={"출근자"}
                    amount={announcement?.goWorkCount ?? "0"}
                    color="secondary"
                    variant="contained"
                    fontSize={24}
                  />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <STcomp
                    title={"결근자"}
                    amount={announcement?.workAbsenceCount ?? "0"}
                    color="secondary"
                    variant="contained"
                    fontSize={24}
                  />
                </Grid>
              </Grid>
            </MDBox>

            <MDBox mt={2}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>

            <MDBox p={2}>
              <MDBox>{!loading && getStepContent(activeStep)}</MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default SiteDetails;
