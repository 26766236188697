/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FormEvent, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Apis from "apis/remotes";
import { handleInput } from "utils/PhoneString";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";

function Basic(): JSX.Element {
  const navigate = useNavigate();
  const [form] = useForm();
  // const [rememberMe, setRememberMe] = useState<boolean>(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const postAuthInfo = (e: FormData) => {
    // e.preventDefault();
    console.log(e);

    message.success("문의 내용 전달이 완료되었습니다.");
    form.resetFields();
    return;

    // 폼 요소에서 email과 password 값을 가져오기
    // const email = (e.currentTarget.elements.namedItem("email") as HTMLInputElement)?.value;
    // const password = (e.currentTarget.elements.namedItem("password") as HTMLInputElement)?.value;

    // Apis.postAuth({ email, password, role: "master" })
    //   .then((resolve) => {
    //     console.log(resolve);
    //     navigate("/dashboard", { replace: true });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // useEffect(() => {
  //   Apis.getAuth()
  //     .then((resolve) => {
  //       navigate("/dashboard", { replace: true });
  //     })
  //     .catch((error: any) => {
  //       console.log("ee", error);
  //     });
  // }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h2" fontWeight="medium" color="white" mt={1}>
            구슬땀
          </MDTypography>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            문의하기
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Form form={form} onFinish={postAuthInfo} layout="vertical" autoComplete="off">
            <MDBox mb={2}>
              <Form.Item
                label="성명"
                name="name"
                rules={[{ required: true, message: "성명을 입력하세요." }]}
              >
                <Input maxLength={40} placeholder="성명을 입력하세요" />
              </Form.Item>
            </MDBox>

            <MDBox mb={2}>
              <Form.Item
                label="핸드폰"
                name="phone"
                rules={[
                  { required: true, message: "핸드폰 번호를 입력하세요." },
                  { pattern: /^[0-9]{10,11}$/, message: "올바른 핸드폰 번호를 입력하세요." },
                ]}
              >
                <Input
                  maxLength={11}
                  onInput={handleInput}
                  placeholder="핸드폰 번호를 입력하세요"
                />
              </Form.Item>
            </MDBox>

            <MDBox mb={2}>
              <Form.Item
                label="문의내용"
                name="memo"
                rules={[{ required: false, message: "문의 내용을 입력하세요." }]}
              >
                <Input.TextArea
                  maxLength={255}
                  placeholder="앱에 문의하실 내용을 입력하세요."
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              </Form.Item>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  제출하기
                </Button>
              </Form.Item>
            </MDBox>
          </Form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
