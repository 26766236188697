import { Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { WorkData } from "apis/response";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import { Tag } from "antd";

// interface newWork {
//   title: string;
//   data: any[];
// }

const tableColumn: ColumnsType<WorkData> = [
  {
    title: "현장명",
    dataIndex: "title",
    key: "title",
    width: "25%",
    ellipsis: true,

    // 여기 해당 공고가 로터리 공고이면 tag '로터리' 추가 색깔은 노란색으로

    // 여기에 record의 isLottery가 true이면 배지 하나 추가해서 '로터리' 하나 추가해봐
    render: (title: string, record: WorkData) => (
      // <Typography
      //   variant="h5"
      //   fontWeight={700}
      //   style={{
      //     maxWidth: "200px",
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //     whiteSpace: "nowrap",
      //   }}
      // >
      //   {title}
      // </Typography>
      <div style={{ display: "flex", alignItems: "center", gap: "2px", maxWidth: "250px" }}>
        {record.isLottery && <Tag color="gold">로터리</Tag>}
        <Typography
          variant="h5"
          fontWeight={700}
          style={{
            maxWidth: "250px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
      </div>
    ),
  },
  {
    title: "모집인원",
    dataIndex: "maxRecruitment",
    key: "maxRecruitment",
    ellipsis: true,
    render: (maxRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              모집인원
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {maxRecruitment}
        </Typography>
      </MDBox>
    ),
  },

  {
    title: "지원자",
    dataIndex: "workApplyCount",
    key: "workApplyCount",
    ellipsis: true,
    render: (workApplyCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              지원자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {workApplyCount}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "지원승인",
    dataIndex: "currentRecruitment",
    key: "currentRecruitment",
    ellipsis: true,
    render: (currentRecruitment: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              지원승인
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {currentRecruitment}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "출근자",
    dataIndex: "goWorkCount",
    key: "goWorkCount",
    ellipsis: true,
    render: (goWorkCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              출근자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {goWorkCount}
        </Typography>
      </MDBox>
    ),
  },
  {
    title: "결근자",
    dataIndex: "workAbsenceCount",
    key: "workAbsenceCount",
    ellipsis: true,
    render: (workAbsenceCount: number) => (
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBadge
          size="lg"
          variant="contained"
          badgeContent={
            <Typography fontSize={14} fontWeight={700} color={"white"}>
              결근자
            </Typography>
          }
        />
        <Typography variant="h6" color="info" fontWeight={500}>
          {workAbsenceCount}
        </Typography>
      </MDBox>
    ),
  },
];
export { tableColumn };
// export type { newWork };
