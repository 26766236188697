import { Card, Divider } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui icons
import MDBox from "components/MDBox";
import MDImage from "components/MDImage";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Apis from "apis/remotes";
import { message } from "antd";
import { IUtilImageById } from "apis/response";

function LotteryThumbNailDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [image, setImage] = useState<IUtilImageById>();
  const [loading, setLoading] = useState(false);
  /** 공고용 이미지 삭제 버튼 클릭 */
  const handleDeleteImage = () => {
    setLoading(true);
    console.log(id);
    Apis.deleteUtilImage(id)
      .then((resolve) => {
        navigate("/utility-management/lottery-work-thumbnail-list");
        message.success("공고 썸네일 이미지가 제거되었습니다.");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    Apis.getUtilImageById(id)
      .then((resolve) => {
        setImage(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <MDBox mb={2} lineHeight={1} display={"flex"} justifyContent={"space-between"}>
              <MDTypography variant="button">등록일 :{image?.createdAt}</MDTypography>
            </MDBox>
            <MDBox opacity={0.3}>
              <Divider />
            </MDBox>
            <MDImage image={image?.image} width={300} />
          </MDBox>
          <MDBox p={3} display={"flex"} justifyContent={"end"} gap={2}>
            <MDButton
              color="error"
              size="small"
              onClick={() => handleDeleteImage()}
              disabled={loading}
            >
              로터리 공고 썸네일 이미지 삭제
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default LotteryThumbNailDetails;
