import { Card } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { tableColumn } from "./components/data";

import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IUtilImages } from "apis/response";
import { paramsFilter } from "utils/ParamsFilter";
import Apis from "apis/remotes";

function ThumbNailList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [notices, setNotices] = useState<IUtilImages[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/utility-management/work-thumbnail-list${paramsFilter({
        page,
      })}`
    );
    setCurrentPage(page);
  };
  useEffect(() => {
    Apis.getUtilImages({ isLottery: false, page: currentPage, perPage: 10 })
      .then((resolve) => {
        setNotices(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox py={3} display="flex" justifyContent="space-between">
          {/* <MDButton variant="outlined" color="dark">
            엑셀 다운
          </MDButton> */}
          <Link to={"/utility-management/new-work-thumbnail"}>
            <MDButton variant="gradient" color="info">
              공고 등록용 이미지 생성
            </MDButton>
          </Link>
        </MDBox>

        <Card>
          <Table
            pagination={false}
            rowKey={(record) => record.workImageId}
            scroll={{ x: "max-content" }}
            dataSource={notices}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/utility-management/work-thumbnail-detail/${record.workImageId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default ThumbNailList;
