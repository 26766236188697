import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import { Upload, UploadFile, UploadProps, message } from "antd";
import Form, { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import Apis from "apis/remotes";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { PlusOutlined } from "@ant-design/icons";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { handleUploadCompMultiFiles, props } from "utils/Upload";

const { Dragger } = Upload;

interface NoticeSubmitForm {
  title: string;
  content: string;
}

function NewThumbNail(): JSX.Element {
  const [form] = useForm();

  const [siteThumbnail, setSiteThumbnail] = useState<UploadFile[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCreateImage = async (noticeSubmitForm: NoticeSubmitForm) => {
    if (siteThumbnail.length === 0) {
      message.error("이미지를 업로드해주세요.");
      return;
    }
    setLoading(false);
    console.log("noticeSubmitForm", noticeSubmitForm);
    Apis.postUtilImage({
      image: siteThumbnail[0]?.url.replace(process.env.REACT_APP_IMG_ENDPOINT, "") ?? "",
      isLottery: false,
    })
      .then((resolve) => {
        message.success("공고용 썸네일 이미지가 등록되었습니다.");
        navigate("/utility-management/work-thumbnail-list");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeSiteThumbnailFile: UploadProps["onChange"] = async ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === "uploading") {
      // 업로드 중인 파일이 있으면 fileList만 업데이트하고 종료
      setSiteThumbnail(newFileList);
      return;
    }

    try {
      const image = await handleUploadCompMultiFiles("image", newFileList, "work");

      // 파일 업로드 성공 후, 미리보기 이미지를 업데이트합니다.
      // 이때, presignedData.url은 서버에서 반환한 파일의 URL입니다.
      setSiteThumbnail([
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: image[0], // 서버에서 반환한 파일의 최종 URL
        },
      ]);
    } catch (error) {
      console.error("File upload failed:", error);
      message.error("파일 업로드에 실패했습니다.");
      // 업로드 실패 시, 현재 파일 리스트에서 해당 파일 제거
      setSiteThumbnail(newFileList.filter((f) => f.uid !== file.uid));
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox p={3}>
            <Form
              labelCol={{ sm: { span: 4 }, span: 24 }}
              wrapperCol={{ sm: { span: 20 }, span: 24 }}
              form={form}
              onFinish={handleCreateImage}
              autoComplete="off"
            >
              <FormItem
                label="공고용 썸네일 이미지"
                name="image"
                rules={[{ required: true, message: "공고용 썸네일을 등록하세요." }]}
              >
                <Upload.Dragger
                  style={{ width: "200px" }}
                  {...props}
                  multiple={false}
                  fileList={siteThumbnail}
                  onChange={onChangeSiteThumbnailFile}
                  accept="image/png, image/jpg, image/jpeg"
                  maxCount={1}
                >
                  <PlusOutlined />
                </Upload.Dragger>
              </FormItem>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <MDButton
                    LinkComponent={Link}
                    to={"/utility-management/work-thumbnail-list"}
                    fullWidth
                    variant="contained"
                    color="light"
                  >
                    취소
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    disabled={loading}
                    fullWidth
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    등록
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default NewThumbNail;
