import { Grid, Typography } from "@mui/material";
import { Pagination, PaginationProps, Table } from "antd";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { paramsFilter } from "utils/ParamsFilter";
import { tableColumn } from "./components/data";
import { INewWork, WorkData } from "apis/response";
import Apis from "apis/remotes";
import Search from "antd/es/input/Search";
import { kMaxLength } from "buffer";
import { ColumnProps, ColumnType, ColumnsType } from "antd/es/table";

function SiteListNew(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const page = params.get("page");

  const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [workList, setWorkList] = useState<INewWork[]>([]);
  const [total, setTotal] = useState(0);
  const [showSpecialOffer, setShowSpecialOffer] = useState(false);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
    navigate(
      `/announcement-management/site-list-new${paramsFilter({
        search,
        page,
      })}`
    );
    setCurrentPage(page);
  };
  const handleSearch = (text: string) => {
    setCurrentPage(1);
    navigate(
      `/announcement-management/site-list-new${paramsFilter({
        search: text,
        page: 1,
      })}`
    );
  };
  function renderDepao(work: INewWork) {
    const getColumns = (): any => {
      const baseColumns = [
        {
          title: "특별 제안 전송",
          key: "specialOffer",
          width: 150,
          align: "center",
          render: (_: any, work: WorkData) =>
            showSpecialOffer ? (
              <MDButton
                variant="contained"
                color="info"
                size="small"
                LinkComponent={Link}
                to={`/announcement-management/special-offer/${work.workId}`}
                onClick={(e) => e.stopPropagation()}
              >
                특별 제안 전송
              </MDButton>
            ) : null,
        },
        ...tableColumn,
      ];

      return showSpecialOffer ? baseColumns : tableColumn;
    };
    return (
      <MDBox py={1}>
        <MDBox p={1}>
          <Typography variant="h5">{work.workDay}</Typography>
        </MDBox>
        <Table
          scroll={{ x: "max-content" }}
          showHeader={false}
          pagination={false}
          rowKey={(record) => record.workId}
          dataSource={work.workData}
          style={{ cursor: "pointer" }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(`/announcement-management/site-details/${record.workId}`);
              },
            };
          }}
          columns={getColumns()}
        />
      </MDBox>
    );
  }
  useEffect(() => {
    Apis.getNewWork({ page: currentPage, perPage: 5, search })
      .then((resolve) => {
        setWorkList(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, search]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container py={3} spacing={2}>
          {/* <Grid item xs={12} sm={3}>
            <MDButton variant="outlined" color="dark">
              엑셀 다운
            </MDButton>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Search
              maxLength={30}
              placeholder="공고명을 검색하세요."
              onSearch={(text) => handleSearch(text)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox display="flex" justifyContent="end" gap={1}>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("cl");
                  setShowSpecialOffer(!showSpecialOffer);
                }}
              >
                특별 제안
              </MDButton>
              <MDButton
                LinkComponent={Link}
                to={"/announcement-management/new-announcement"}
                variant="gradient"
                color="info"
              >
                공고 등록
              </MDButton>
              <MDButton
                LinkComponent={Link}
                to={"/announcement-management/lottery-new-announcement"}
                variant="gradient"
                color="dark"
              >
                로터리 공고 등록
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>

        <MDBox flex={"flex"} flexDirection={"column"}>
          {workList.map((work, index) => {
            return <MDBox key={`depao-table-${index}`}>{renderDepao(work)}</MDBox>;
          })}
        </MDBox>
        <MDBox my={2}>
          <Pagination
            current={currentPage}
            onChange={handleOnChangePage}
            pageSize={5}
            total={total}
            showSizeChanger={false}
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default SiteListNew;
