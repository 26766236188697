import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { handleInput } from "utils/PhoneString";

interface Props {
  open: boolean;
  handleOpenState: Function;
  handlePostAdminPhone: Function;
}

const { Option } = Select;

function AdminPhoneUploadModal({
  open,
  handleOpenState,
  handlePostAdminPhone,
}: Props): JSX.Element {
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    console.log("Form Values: ", values);
    // 전달할 값 (values가 이미 모든 필드를 담고 있음)
    handlePostAdminPhone(values);
    handleOpenState();
  };

  return (
    <Dialog open={open} onClose={() => handleOpenState()} fullWidth>
      <DialogTitle>
        <MDBox display="flex" justifyContent="center">
          종합 데이터 전송 관리자 추가
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <MDBox mb={3}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              adminPhoneType: "totalDataEveryday", // 기본값 설정
            }}
          >
            {/* adminPhoneName 필드 (필수) */}
            <Form.Item
              label="관리자 이름"
              name="adminPhoneName"
              rules={[{ required: true, message: "관리자 이름을 입력해주세요" }]}
            >
              <Input placeholder="관리자 이름을 입력하세요" maxLength={40} showCount />
            </Form.Item>

            {/* adminPhone 필드 (필수, 유효성 검사) */}
            <Form.Item
              label="전화번호"
              name="adminPhone"
              rules={[
                { required: true, message: "전화번호를 입력해주세요" },
                {
                  pattern: /^[0-9]{10,11}$/,
                  message: "올바른 전화번호를 입력해주세요 (숫자 10-11자리)",
                },
              ]}
            >
              <Input
                type="tel"
                placeholder="전화번호를 입력하세요"
                maxLength={11}
                showCount
                onInput={handleInput}
              />
            </Form.Item>

            {/* adminPhoneMemo 필드 (선택) */}
            <Form.Item label="메모" name="adminPhoneMemo">
              <TextArea placeholder="메모를 입력하세요 (선택사항)" maxLength={255} showCount />
            </Form.Item>

            {/* adminPhoneType 필드 (필수) */}
            <Form.Item
              label="전화 타입"
              name="adminPhoneType"
              rules={[{ required: true, message: "전화 타입을 선택해주세요" }]}
            >
              <Select getPopupContainer={(triggerNode) => triggerNode.parentNode}>
                <Option value="totalDataEveryday">매일 전송</Option>
                <Option value="totalDataOneDay">매주 월요일 전송</Option>
              </Select>
            </Form.Item>
          </Form>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => handleOpenState()}>취소</MDButton>
        <MDButton onClick={() => form.submit()} color="info">
          추가하기
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AdminPhoneUploadModal;
