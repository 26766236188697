import { Table, Typography, Card } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import Apis from "apis/remotes";
import { IUtilTracking } from "apis/response";

import { ColumnsType } from "antd/es/table";

const initData: IUtilTracking = {
  mobilePostWorkHomeTrueClick: 0,
  mobilePostWorkHomeFalseClick: 0,
  homeSlideCount: 0,
  employDashCount: 0,
  employWorkCount: 0,
  employWorkReserveCount: 0,
  employWorkingListCount: 0,
  employWorkListCount: 0,
  employSettlementCount: 0,
  employPartnerCount: 0,
};

function TrackingManagementView() {
  const [data, setData] = useState<IUtilTracking>(initData);

  useEffect(() => {
    Apis.getUtilTracking()
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // 테이블에 들어갈 데이터 정의
  const columns: ColumnsType<any> = [
    {
      title: "항목",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "수집 시작일",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "클릭 수",
      dataIndex: "value",
      key: "value",
      align: "right",
      render: (value: number) => value.toLocaleString(),
    },
  ];
  /**
 * 
 * mobilePostWorkHomeTrueClick:  모바일 홈 버튼(접속/새로고침 수) /// 수집시작일 2024/10/21 11:00
  mobilePostWorkHomeFalseClick: 0, 모바일 홈 햄버거 버튼 2024/10/21 11:00
  homeSlideCount: 0, 모바일 메인화면 슬라이드  /// 다음 앱 업데이트  예정
  employDashCount: 0, 인력사무소 대시보드 /// 2024/100/23 11:00
  employWorkCount: 0, 인력사무소 공고 관리
  employWorkReserveCount: 0, 인력사무소 예약공고 관리
  employWorkingListCount: 0, 인력사무소 근무중 관리
  employWorkListCount: 0, 인력사무소 근로자 관리
  employSettlementCount: 0, 인력사무소 정산 관리
  employPartnerCount: 0, 인력사무소 파트너 관리
 */
  const createDataSource = (trackingData: IUtilTracking) => [
    {
      key: "1",
      label: "모바일 홈 버튼(접속/새로고침 수)",
      createdAt: "2024/10/21 11:00",
      value: trackingData.mobilePostWorkHomeTrueClick,
    },
    {
      key: "2",
      label: "모바일 홈 햄버거 버튼",
      createdAt: "2024/10/21 11:00",
      value: trackingData.mobilePostWorkHomeFalseClick,
    },
    {
      key: "3",
      label: "모바일 메인화면 슬라이드",
      createdAt: "다음 앱 업데이트  예정",
      value: trackingData.homeSlideCount,
    },
    {
      key: "4",
      label: "인력사무소 대시보드",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employDashCount,
    },
    {
      key: "5",
      label: "인력사무소 공고 관리",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employWorkCount,
    },
    {
      key: "6",
      label: "인력사무소 예약공고 관리",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employWorkReserveCount,
    },
    {
      key: "7",
      label: "인력사무소 근무중 관리",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employWorkingListCount,
    },
    {
      key: "8",
      label: "인력사무소 근로자 관리",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employWorkListCount,
    },
    {
      key: "9",
      label: "인력사무소 정산 관리",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employSettlementCount,
    },
    {
      key: "10",
      label: "인력사무소 파트너 관리",
      createdAt: "2024/10/23 11:00",
      value: trackingData.employPartnerCount,
    },
  ];

  const dataSource = createDataSource(data);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <Typography.Title level={5}>Tracking Data</Typography.Title>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default TrackingManagementView;
